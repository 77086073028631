<template>
  <v-card width="500px">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit="onSaveClick"
    >
      <v-row
        no-gutters
        class="pa-5 flex-no-wrap align-end"
      >
        <span class="text-body-2">{{ rootPath }}/</span><span>
          <v-text-field
            v-model="formData.folderName"
            dense
            hide-details
            required
            :rules="[v => !!v]"
          /></span>
      </v-row>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="closeDialog"
        >
          Loobu
        </v-btn>
        <v-btn
          color="primary"
          @click="onSaveClick"
        >
          Salvesta
        </v-btn>
      </v-toolbar>
    </v-form>
  </v-card>
</template>
<script>
import { mdiPaperclip } from '@mdi/js';
import storageModule from '@/store/modules/storage';
import dialogModule from '@/store/modules/dialog';
import {
  computed, reactive, toRefs, ref,
} from '@vue/composition-api';

const vectorIcons = { mdiPaperclip };
export default {
  setup() {
    const { dialogPayload, closeDialog } = dialogModule();
    const { createStorageDirectory } = storageModule();
    const fullPath = computed(() => dialogPayload.value && dialogPayload.value.fullPath);
    const rootPath = computed(() => decodeURI(fullPath.value.split('/photos')[1]));
    const form = ref(null);
    const state = reactive({
      valid: true,
      formData: {
        folderName: '',
      },
    });
    const onSaveClick = async () => {
      await form.value.validate();
      if (!state.valid) return;
      const { collection } = dialogPayload.value;
      const path = `${fullPath.value }/${ state.formData.folderName}`;
      createStorageDirectory({ path, collection });
      closeDialog();
    };
    return {
      ...vectorIcons,
      ...toRefs(state),
      form,
      onSaveClick,
      dialogPayload,
      closeDialog,
      rootPath,
    };
  },
};
</script>
